import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import MeasurementEntity from '../../../entities/measurement.entity'
import router from '../../../router'
import store from '../../../store'

export default function useMeasurementDetails() {
  const toast = useToast()

  /**
   * Property that defines whether the `measurement` is being
   * loaded.
   */
  const loadingMeasurement = ref(false)

  /**
   * Property that defines the `measurement` data.
   *
   * @type {import("@vue/composition-api").Ref<MeasurementEntity}
   */
  const measurement = ref(null)

  /**
   * Property that defines the measurement `frontal picture`.
   */
  const frontalPicture = ref('')

  /**
   * Property that defines the measurement `side picture`.
   */
  const sidePicture = ref('')

  const cardPicture = ref('')

  const medicalPrescription = ref('')

  const fetchMeasurement = async (parent) => {
    loadingMeasurement.value = true

    const id = router.currentRoute.params.id
    let path = ''

    if (parent) {
      path += `${parent}/`
    }

    if (!path.includes('measurements')) {
      path += `measurements/${id}`
    }

    try {
      measurement.value = await store.dispatch(
        'measurements/fetchOneMeasurement',
        path,
      )
      frontalPicture.value = await store.dispatch(
        'measurements/getImageUrl',
        measurement.value.frontal_picture,
      )
      sidePicture.value = await store.dispatch(
        'measurements/getImageUrl',
        measurement.value.side_picture,
      )
      cardPicture.value = await store.dispatch(
        'measurements/getImageUrl',
        measurement.value.card_picture,
      )
      medicalPrescription.value = await store.dispatch(
        'measurements/getImageUrl',
        measurement.value.medical_prescription,
      )
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.measurement.failure'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

      router.push({
        name: window.location.pathname.includes('/admin')
          ? 'measurements'
          : 'error-404',
      })
    } finally {
      loadingMeasurement.value = false
    }
  }

  return {
    fetchMeasurement,
    frontalPicture,
    loadingMeasurement,
    measurement,
    sidePicture,
    cardPicture,
    medicalPrescription
  }
}
