<template>
  <b-row id="originalContent">
    <b-col cols="12" md="6" lg="6" xl="6">
      <b-tabs v-model="currentTab" pills>
        <b-tab active>
          <template #title>
            <lx-icon :icon="mdiFaceMan" />
            <span>{{ $t('admin.measurements.details.frontalPicture') }}</span>
          </template>

          <div
            v-if="!loadingMeasurement && frontalPicture"
            class="lx__frontal lx__image"
            :class="{ 'hide-markers': hideMarkers }"
          >
            <img alt="frontal" :src="frontalPicture" />

            <div class="lx__button-container">
              <b-button
                v-ripple.400="'#fff2'"
                v-b-tooltip.hover.bottom.v-primary
                title="Toggle markers"
                variant="gradient-primary"
                class="btn-icon rounded-circle lx__toggle"
                @click="hideMarkers = !hideMarkers"
              >
                <feather-icon
                  size="18"
                  :icon="hideMarkers ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
            </div>
          </div>

          <b-alert
            v-if="!loadingMeasurement && !frontalPicture"
            show
            variant="warning"
          >
            <div class="alert-body text-center">
              {{ $t('admin.measurements.details.noFrontalPicture') }}
            </div>
          </b-alert>

          <b-row v-if="loadingMeasurement" align-h="center" class="mt-5">
            <b-spinner />
          </b-row>
        </b-tab>

        <b-tab>
          <template #title>
            <lx-icon :icon="mdiFaceManProfile" />
            <span>{{ $t('admin.measurements.details.sidePicture') }}</span>
          </template>

          <div
            v-if="!loadingMeasurement && sidePicture"
            class="lx__side lx__image"
            :class="{ 'hide-markers': hideMarkers }"
          >
            <img alt="side" :src="sidePicture" />

            <div class="lx__button-container">
              <b-button
                v-ripple.400="'#fff2'"
                v-b-tooltip.hover.bottom.v-primary
                title="Toggle markers"
                variant="gradient-primary"
                class="btn-icon rounded-circle lx__toggle"
                @click="hideMarkers = !hideMarkers"
              >
                <feather-icon
                  size="18"
                  :icon="hideMarkers ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
            </div>
          </div>

          <b-alert
            v-if="!loadingMeasurement && !sidePicture"
            show
            variant="warning"
          >
            <div class="alert-body text-center">
              {{ $t('admin.measurements.details.noSidePicture') }}
            </div>
          </b-alert>

          <b-row v-if="loadingMeasurement" align-h="center" class="mt-5">
            <b-spinner />
          </b-row>
        </b-tab>

        <b-tab>
          <template #title>
            <lx-icon :icon="mdiCreditCard" />
            <span>{{ $t('admin.measurements.details.creditCard') }}</span>
          </template>

          <div
            v-if="!loadingMeasurement && cardPicture"
            class="lx__card lx__image"
            :class="{ 'hide-markers': hideMarkers }"
          >
            <img alt="card" :src="cardPicture" />

            <div class="lx__button-container">
              <b-button
                v-ripple.400="'#fff2'"
                v-b-tooltip.hover.bottom.v-primary
                title="Toggle markers"
                variant="gradient-primary"
                class="btn-icon rounded-circle lx__toggle"
                @click="hideMarkers = !hideMarkers"
              >
                <feather-icon
                  size="18"
                  :icon="hideMarkers ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
            </div>
          </div>

          <b-alert
            v-if="!loadingMeasurement && !cardPicture"
            show
            variant="warning"
          >
            <div class="alert-body text-center">
              {{ $t('admin.measurements.details.noCardPicture') }}
            </div>
          </b-alert>

          <b-row v-if="loadingMeasurement" align-h="center" class="mt-5">
            <b-spinner />
          </b-row>
        </b-tab>

        <!-- Receita médica -->
        <b-tab>
          <template #title>
            <lx-icon :icon="mdiClipboardPlusOutline" />
            <span>{{
              $t('admin.measurements.details.medicalPrescription')
            }}</span>
          </template>

          <div
            v-if="!loadingMeasurement && medicalPrescription"
            class="lx__side lx__image"
            :class="{ 'hide-markers': hideMarkers }"
          >
            <img alt="side" :src="medicalPrescription" />

            <div class="lx__button-container">
              <b-button
                v-ripple.400="'#fff2'"
                v-b-tooltip.hover.bottom.v-primary
                title="View image"
                variant="gradient-primary"
                class="btn-icon rounded-circle lx__toggle"
                :href="medicalPrescription"
                target="_blank"
              >
                <feather-icon size="18" icon="ExternalLinkIcon" />
              </b-button>
            </div>
          </div>

          <b-alert
            v-if="!loadingMeasurement && !medicalPrescription"
            show
            variant="warning"
          >
            <div class="alert-body text-center">
              {{ $t('admin.measurements.details.noPrescription') }}
            </div>
          </b-alert>

          <b-row v-if="loadingMeasurement" align-h="center" class="mt-5">
            <b-spinner />
          </b-row>
        </b-tab>
      </b-tabs>
    </b-col>

    <b-col
      :class="{
        'mt-1': ['xs', 'sm'].includes($store.getters['app/currentBreakPoint']),
      }"
    >
      <b-card>
        <b-row v-if="loadingMeasurement" align-h="center" class="mt-2">
          <b-spinner />
        </b-row>
        <div v-else>
          <b-form-group :label="$t('admin.measurements.details.consumer')">
            <b-form-input
              v-model="measurement.consumer"
              id="fh-name"
              :placeholder="$t('admin.measurements.details.consumerName')"
            />
          </b-form-group>
          <b-form-group :label="$t('admin.measurements.details.serviceOrder')">
            <b-form-input
              v-model="measurement.serviceOrder"
              id="fh-name"
              :placeholder="$t('admin.measurements.details.serviceOrder')"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="updateConsumer"
            block
          >
            {{ $t('admin.measurements.details.editConsumer') }}
          </b-button>
        </div>
      </b-card>

      <b-card>
        <b-row>
          <b-col>
            <b-card-title class="text-center float-left m-0">{{
              $t('admin.measurements.details.results')
            }}</b-card-title>
          </b-col>
          <b-col>
            <div class="float-right" v-if="measurement">
              {{ ISO_to_date(measurement.createdAt) }} /
              {{ beautyParserHourToString(measurement.createdAt) }}
            </div>
          </b-col>
        </b-row>

        <b-row v-if="loadingMeasurement" align-h="center" class="mt-2">
          <b-spinner />
        </b-row>

        <b-alert
          v-else-if="!measurement.results"
          show
          variant="warning"
          class="mt-2"
        >
          <div class="alert-body text-center">
            {{ $t('admin.measurements.details.noResults') }}
          </div>
        </b-alert>

        <div v-else>
          <div v-if="measurement">
            <div
              id="results"
              v-if="
                measurement.results.dnp_ponte ||
                measurement.results.angulo_pantoscopico ||
                measurement.results.dnp_aro
              "
            >
              <b-row class="m-0 mt-1" align-h="between">
                <h4>
                  {{ $t('admin.measurements.details.glassesMeasurement') }}
                </h4>

                <div>ID: {{ measurement.id }}</div>
              </b-row>

              <div class="lx__divider"></div>

              <b-row v-if="measurement.results.dnp_ponte">
                <b-col v-if="measurement.results.dnp_ponte" cols="12">
                  <h5 class="text-center">
                    {{ $t('admin.measurements.details.simpleVision') }}
                  </h5>

                  <b-row class="mx-1">
                    <b-col>
                      <div class="text-center">
                        {{ $t('admin.measurements.details.rightEye') }}:
                        {{ measurement.results.dnp_ponte.od }}mm
                      </div>
                    </b-col>

                    <b-col>
                      <div class="text-center">
                        {{ 'DP' }}:
                        {{
                          sumOfDp(
                            measurement.results.dnp_ponte.od,
                            measurement.results.dnp_ponte.oe,
                          )
                        }}mm
                      </div>
                    </b-col>

                    <b-col>
                      <div class="text-center">
                        {{ $t('admin.measurements.details.leftEye') }}:
                        {{ measurement.results.dnp_ponte.oe }}mm
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr
                v-if="
                  measurement.results.dnp_aro &&
                  (measurement.results.dnp_ponte ||
                    measurement.results.angulo_pantoscopico)
                "
              />

              <b-row v-if="measurement.results.dnp_aro" class="mt-2">
                <b-col>
                  <h5 class="text-center mb-1">
                    {{ $t('admin.measurements.details.progressiveLens') }}
                  </h5>
                  <b-row style>
                    <!-- DNP -->
                    <b-col cols="12">
                      <h6 class="lx__subtitle text-center">DNP</h6>
                      <b-row class="mx-1">
                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.rightEye') }}:
                            {{ measurement.results.dnp_aro.od }}mm
                          </div>
                        </b-col>

                        <b-col>
                          <div class="text-center">
                            {{ 'DP' }}:
                            {{
                              sumOfDp(
                                measurement.results.dnp_aro.od,
                                measurement.results.dnp_aro.oe,
                              )
                            }}mm
                          </div>
                        </b-col>

                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.leftEye') }}:
                            {{ measurement.results.dnp_aro.oe }}mm
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <hr />

                    <!-- ALTURA DA MONTAGEM -->
                    <b-col cols="12" class="mt-1">
                      <h6 class="lx__subtitle text-center">
                        {{ $t('admin.measurements.details.assemblyHeight') }}
                      </h6>
                      <b-row class="mx-1">
                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.rightEye') }}:
                            {{ measurement.results.altura_de_montagem.od }}mm
                          </div>
                        </b-col>

                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.leftEye') }}:
                            {{ measurement.results.altura_de_montagem.oe }}mm
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- DIÁMETRO DA LENTE -->
                    <b-col cols="12" class="mt-1">
                      <h6 class="lx__subtitle text-center">
                        {{ $t('admin.measurements.details.lensDiameter') }}
                      </h6>
                      <b-row class="mx-1">
                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.rightEye') }}:
                            {{ measurement.results.diametro_da_lente.od }}mm
                          </div>
                        </b-col>

                        <b-col>
                          <div class="text-center">
                            {{ $t('admin.measurements.details.leftEye') }}:
                            {{ measurement.results.diametro_da_lente.oe }}mm
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr
                v-if="
                  measurement.results.angulo_pantoscopico &&
                  (measurement.results.dnp_aro || measurement.results.dnp_ponte)
                "
              />
              <template
                v-if="
                  measurement.results.tamanho_aro &&
                  measurement.results.angulo_pantoscopico
                "
              >
                <b-row class="mt-1">
                  <b-col
                    v-if="measurement.results.angulo_pantoscopico"
                    cols="6"
                  >
                    <h5 class="text-center">
                      {{ $t('admin.measurements.details.pantoscopicAngle') }}
                    </h5>
                    <div class="text-center">
                      {{ $t('admin.measurements.details.degrees') }}:
                      {{ measurement.results.angulo_pantoscopico }}°
                    </div>
                  </b-col>
                  <b-col v-if="measurement.results.tamanho_aro" cols="6">
                    <h5 class="text-center">
                      {{ $t('admin.measurements.details.aroSize') }}
                    </h5>
                    <b-row>
                      <b-col>
                        <div class="text-center">
                          {{ $t('admin.measurements.details.width') }}:
                          {{ measurement.results.tamanho_aro.largura }}mm
                        </div>
                      </b-col>
                      <b-col>
                        <div class="text-center">
                          {{ $t('admin.measurements.details.height') }}:
                          {{ measurement.results.tamanho_aro.altura }}mm
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>

              <template
                v-if="
                  measurement.results.tamanho_aro &&
                  !measurement.results.angulo_pantoscopico
                "
              >
                <b-row class="mt-3">
                  <b-col cols="12">
                    <h5 class="text-center">
                      {{ $t('admin.measurements.details.aroSize') }}
                    </h5>
                    <b-row>
                      <b-col>
                        <div class="text-center">
                          {{ $t('admin.measurements.details.width') }}:
                          {{ measurement.results.tamanho_aro.largura }}mm
                        </div>
                      </b-col>
                      <!--
                    <b-col>
                      <div class="text-center">
                        {{ $t('admin.measurements.details.ponteSize') }}:
                        {{ measurement.measurement_progress.cursors.ponte }}mm
                      </div>
                    </b-col>
                    -->
                      <b-col>
                        <div class="text-center">
                          {{ $t('admin.measurements.details.height') }}:
                          {{ measurement.results.tamanho_aro.altura }}mm
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </div>

            <div v-if="measurement.results.dnp_card">
              <b-row class="m-0 mt-2" align-h="center">
                <h4>
                  {{ $t('admin.measurements.details.cardMeasurement') }}
                </h4>
              </b-row>

              <div class="lx__divider"></div>

              <b-row>
                <b-col>
                  <b-row class="mx-2">
                    <b-col>
                      <div class="text-center">
                        {{ $t('admin.measurements.details.rightEye') }}:
                        {{ measurement.results.dnp_card.od }}mm
                      </div>
                    </b-col>

                    <b-col>
                      <div class="text-center">
                        {{ $t('admin.measurements.details.leftEye') }}:
                        {{ measurement.results.dnp_card.oe }}mm
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-if="measurement">
          <div id="consumer" style="display: none" v-if="measurement">
            <h4>{{ $t('admin.measurements.details.consumerName') }}:</h4>
            <p>{{ measurement.consumer }}</p>
            <h4>{{ $t('admin.measurements.details.serviceOrder') }}:</h4>
            <p>{{ measurement.serviceOrder }}</p>
          </div>
          <b-row class="mt-2 m-0 mt-1 justify-content-between">
            <div class="lx__divider"></div>
            <b-col
              cols="12"
              v-if="measurement.measurement_progress && measurement.measurement_progress.cursors.ponte"
            >
              <h5 class="text-center">
                {{ $t('admin.measurements.details.ponteSize') }}
              </h5>
              <div class="text-center">
                {{ measurement.measurement_progress.cursors.ponte }}mm
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="around">
            <b-button
              variant="outline-primary"
              v-if="measurement.results"
              @click="printMeasurement()"
            >
              {{ $t('admin.measurements.details.print') }}
            </b-button>

            <b-button
              id="copy-btn"
              variant="outline-primary"
              size="sm"
              v-if="measurement.results"
              @click="copyMeasurement()"
              v-b-tooltip.click="'Copied on clipboard'"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-button
              variant="primary"
              class="float-right"
              @click="editMeasurement()"
            >
              {{ $t('admin.measurements.details.edit') }}
            </b-button>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  mdiFaceMan,
  mdiFaceManProfile,
  mdiCreditCard,
  mdiClipboardPlusOutline,
  mdiArrowTopRightThinCircleOutline,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import { beautyParserHourToString } from '../../../utils/dates'

import {
  BAlert,
  BCard,
  BCardTitle,
  BCol,
  BRow,
  BSpinner,
  BTab,
  BTabs,
  BButton,
  VBTooltip,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import Icon from '@/components/Icon.vue'
import { ISO_to_date, ISO_to_time } from '@/utils/dates'

import store from '../../../store'

import useMeasurementDetails from './useMeasurementDetails'
import measurementStoreModule from './measurementStoreModule'
import MeasurementEntity from '../../../entities/measurement.entity'
import router from '@/router'
import RoleEnum from '@/utils/roles'
import Controller from '@/controllers/base.controller'

const measurerToolBaseUrl = process.env.VUE_APP_MEASURER_TOOL_URL

export default {
  name: 'MeasurementDetails',

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  components: {
    BAlert,
    BCard,
    BCardTitle,
    BCol,
    BRow,
    BSpinner,
    BTab,
    BTabs,
    BButton,
    BFormInput,
    BFormGroup,

    'lx-icon': Icon,
  },

  data() {
    return {
      beautyParserHourToString,
      mdiFaceMan,
      mdiFaceManProfile,
      mdiCreditCard,
      mdiArrowTopRightThinCircleOutline,
      mdiClipboardPlusOutline,
      currentTab: 0,
      hideMarkers: true,
      imageSize: { width: 0, height: 0, imgWidth: 0, imgHeight: 0 },
    }
  },

  methods: {
    ISO_to_date,
    ISO_to_time,
    sumOfDp(od, oe) {
      return (parseFloat(od) + parseFloat(oe)).toFixed(2)
    },
    async updateConsumer() {
      await this.$store.dispatch('measurements/updateMeasurement', {
        parent: this.$route.query.p,
        id: this.$route.params.id,
        data: {
          consumer: this.measurement.consumer,
          serviceOrder: this.measurement.serviceOrder,
        },
      })
    },
    copyMeasurement() {
      const input = document.createElement('textarea')
      console.log('MEASUREMENT ==>>', this.measurement)
      input.style.position = 'fixed'
      let results = this.measurement.toString()
      console.log(results)
      input.value = results

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    printMeasurement() {
      var originalContent = document.body.innerHTML
      var results = document.getElementById('results').innerHTML
      var consumer = document.getElementById('consumer').innerHTML

      document.body.innerHTML = consumer + results
      window.print()
      document.body.innerHTML = originalContent
      location.reload()
    },
    async editMeasurement() {
      const id = this.$route.params.id
      const parent = this.$route.query.p
      let url = `${measurerToolBaseUrl}/#/tool/${id}?p=${parent}&lang=${this.$i18n.locale}`

      let logo
      let isPlanParent = this.isPlanParent()
      const adminDoc = this.$store.state.auth.userAdminDoc

      if (this.user.role === RoleEnum.SUPER_ADMIN) {
        url += `&isSa=true`
      }

      if (isPlanParent) {
        if (adminDoc) logo = adminDoc.logo
      } else {
        const parentBaseUrl = parent.split('/')
        const length = parentBaseUrl.length
        parentBaseUrl.splice(length - 2, 2)
        const parentDocument = await new Controller().getById(parentBaseUrl)
        logo = parentDocument.data().logo
        if (logo === null || logo === undefined || logo.length === 0) {
          logo = adminDoc.logo
        }
      }

      if (logo) {
        url += `&logo=${logo}`
      }

      window.open(url)
    },
    isPlanParent() {
      if (this.user.role === RoleEnum.SELLER) {
        return false
      } else {
        const planParent =
          this.user.adminDocRef.split('/').length > 2 ? false : true

        if (!planParent) {
          return false
        }
        return true
      }
    },

    /**
     * Reads the image size.
     */
    setImageSize() {
      const image = new Image()
      image.onload = () => {
        const className = !this.currentTab
          ? 'frontal'
          : this.currentTab === 1
          ? 'side'
          : 'card'

        const imageContainer = document.querySelector(
          `.lx__${className}.lx__image`,
        )

        if (!imageContainer) {
          return
        }

        const containerImg = imageContainer.querySelector('img')

        this.imageSize.width = containerImg.offsetWidth
        this.imageSize.height = containerImg.offsetHeight

        switch (this.currentTab) {
          case 0:
            this.setFrontalCursors(this.measurement)
            break
          case 1:
            this.setLateralCursors(this.measurement)
            break
          case 2:
            this.setCardCursors(this.measurement)
            break
          default:
            this.setFrontalCursors(this.measurement)
        }
      }
      image.src = !this.currentTab
        ? this.frontalPicture
        : this.currentTab === 1
        ? this.sidePicture
        : this.cardPicture
    },

    /**
     * Sets all measurement frontal cursors.
     *
     * @param {MeasurementEntity} measurement
     */
    setFrontalCursors(measurement) {
      if (
        !measurement.imageCanvasSize ||
        !measurement.imageCanvasSize.frontal
      ) {
        return
      }

      const redBorder = 'solid 1px red'
      const whiteBorder = 'solid 1px white'
      const yellowBorder = 'solid 1px #fb6c08'

      const frontalCursors = [
        { name: 'c1b3', background: 'red' }, // bridge
        {
          name: 'c2b1',
          border: whiteBorder,
          background: '#fff1',
          borderWidth: '0.5px',
          borderRadius: '50%',
        }, // iris circle
        { name: 'c2b2', background: 'white' }, // iris cross
        { name: 'c2b3', background: 'white' }, // iris cross
        {
          name: 'c3b1',
          border: whiteBorder,
          background: '#fff1',
          borderWidth: '0.5px',
          borderRadius: '50%',
        }, // iris circle
        { name: 'c3b2', background: 'white' }, // iris cross
        { name: 'c3b3', background: 'white' }, // iris cross
      ]

      if (
        measurement.measurement_progress.measurements.complete.includes(
          'glasses front complete',
        )
      ) {
        frontalCursors.push(
          { name: 'c4b1', border: redBorder }, // rim size
          { name: 'c5b1', border: redBorder }, // rim size
        )
      }

      const imageContainer = document.querySelector('.lx__frontal.lx__image')

      this.setCursors(
        frontalCursors,
        measurement,
        imageContainer,
        measurement.imageCanvasSize.frontal,
        'frontal',
      )
    },

    /**
     * Sets all measurement card cursors.
     *
     * @param {MeasurementEntity} measurement
     */
    setCardCursors(measurement) {
      if (!measurement.imageCanvasSize || !measurement.imageCanvasSize.card) {
        return
      }

      const redBorder = 'solid 1px red'
      const whiteBorder = 'solid 1px white'

      const cardCursors = [
        {
          name: 'c11b1',
          transformOrigin: '50% 50%',
          border: redBorder,
          background: '#0004',
        }, // card size
        {
          name: 'c9b1',
          border: whiteBorder,
          background: '#fff1',
          borderWidth: '2px',
          borderRadius: '50%',
        }, // iris circle
        { name: 'c9b2', background: 'white' }, // iris cross
        { name: 'c9b3', background: 'white' }, // iris cross
        {
          name: 'c10b1',
          border: whiteBorder,
          background: '#fff1',
          borderWidth: '2px',
          borderRadius: '50%',
        }, // iris circle
        { name: 'c10b2', background: 'white' }, // iris cross
        { name: 'c10b3', background: 'white' }, // iris cross
        { name: 'c8b2', background: 'red' }, // bridge
        { name: 'c8b3', background: 'red' }, // bridge
        { name: 'c8b4', background: 'red' }, // bridge
        { name: 'c8b7', background: 'red' }, // bridge
      ]

      const imageContainer = document.querySelector('.lx__card.lx__image')

      this.setCursors(
        cardCursors,
        measurement,
        imageContainer,
        measurement.imageCanvasSize.card,
        'card',
      )
    },

    /**
     * Sets all measurement lateral cursors.
     *
     * @param {MeasurementEntity} measurement
     */
    setLateralCursors(measurement) {
      if (
        !measurement.imageCanvasSize ||
        !measurement.imageCanvasSize.lateral
      ) {
        return
      }

      const redBorder = 'solid 1px red'

      const lateralCursors = [
        { name: 'c12b2', transformOrigin: '100% 0', border: redBorder }, // line
        { name: 'c12b3', transformOrigin: '0 0', border: redBorder }, // line
        {
          name: 'c12b5',
          background: '#fff4',
          borderLeft: redBorder,
          borderBottom: redBorder,
          borderRadius: '0 0 0 100%',
        }, // angle
      ]

      const imageContainer = document.querySelector('.lx__side.lx__image')

      this.setCursors(
        lateralCursors,
        measurement,
        imageContainer,
        measurement.imageCanvasSize.lateral,
        'lateral',
      )
    },

    /**
     * Sets the cursors into the image.
     *
     * @param {Object[]} specCursors the image specific cursors name and styles.
     * @param {MeasurementEntity} measurement the current measurement.
     * @param {HTMLDivElement} container the image container element.
     * @param {import('../../../entities/measurement.entity').ImageSize} size the image size properties.
     * @param {string} side defines the side of the image.
     */
    setCursors(specCursors, measurement, container, size, side) {
      let relation

      let imgWidth = size.imgWidth
      let imgHeight = size.imgHeight

      const progress = measurement.measurement_progress
      const resizedImage = progress ? progress.resizedImage : null
      const resizedSize = resizedImage ? resizedImage[side] : null

      let resizedWidthRelation = 1
      let resizedHeightRelation = 1

      if (resizedSize && resizedSize.width && resizedSize.diffX) {
        resizedWidthRelation =
          resizedSize.width / Math.max(size.width, imgWidth)
      }

      if (resizedSize && resizedSize.height && resizedSize.diffY) {
        resizedHeightRelation =
          resizedSize.height / Math.max(size.height, imgHeight)
      }

      imgWidth *= resizedWidthRelation
      imgHeight *= resizedHeightRelation

      const aspectRatio = imgWidth / imgHeight
      let realWidth = this.imageSize.height * aspectRatio

      if (realWidth > this.imageSize.width) {
        realWidth = this.imageSize.width
      }

      if (imgWidth > size.width) {
        relation = realWidth / imgWidth
      } else {
        relation = this.imageSize.height / imgHeight
      }

      const cursors = measurement.measurement_progress.cursors

      const zoomRelation = resizedSize.width / size.imgWidth

      container.querySelector('img').style.transform = `scale(${zoomRelation})`

      container
        .querySelectorAll('.cursors-container')
        .forEach((cursor) => cursor.remove())

      const cursorsContainer = document.createElement('div')
      cursorsContainer.classList.add('cursors-container')
      cursorsContainer.style.width = realWidth + 'px'
      cursorsContainer.style.transform = `translateX(-50%) scale(${zoomRelation})`

      for (let cursor in cursors) {
        if (!specCursors.map((c) => c.name).includes(cursor)) {
          continue
        }

        const c = specCursors.find((c) => c.name === cursor)

        const cursorEl = document.createElement('div')
        cursorEl.classList.add('cursor', cursor)

        const top = cursors[cursor].top.v // 134
        const topGap = (size.height - imgHeight) / 2 // 262
        const topRelation = (top - topGap) * (this.imageSize.height / imgHeight)

        const left = cursors[cursor].left.v
        let leftRelation

        if (imgWidth > size.width) {
          leftRelation =
            (left + (imgWidth - size.width) / 2) * (realWidth / imgWidth)
        } else {
          const imgW = imgWidth * relation
          const cGap = (realWidth - imgW) / 2
          const gap = (size.width - imgWidth) / 2
          const imgLeft = left - gap
          const cImgLeft = imgLeft * relation
          leftRelation = cImgLeft + cGap
        }

        cursorEl.style.zIndex = cursors[cursor]['z-index']

        cursorEl.style.top = topRelation + 'px'
        cursorEl.style.left = leftRelation + 'px'

        cursorEl.style.width = cursors[cursor].width.v * relation + 'px'
        cursorEl.style.height = cursors[cursor].height.v * relation + 'px'

        if (cursors[cursor].rotate || cursors[cursor].rotate === 0) {
          cursorEl.style.transform =
            'rotate(' + cursors[cursor].rotate.v + 'deg)'
          cursorEl.style.transformOrigin = c.transformOrigin
        }

        if (!c.border) {
          cursorEl.style.borderLeft = c.borderLeft
          cursorEl.style.borderTop = c.borderTop
          cursorEl.style.borderRight = c.borderRight
          cursorEl.style.borderBottom = c.borderBottom
        } else {
          cursorEl.style.border = c.border
        }

        cursorEl.style.borderWidth = c.borderWidth
        cursorEl.style.borderRadius = c.borderRadius
        cursorEl.style.background = c.background

        cursorsContainer.appendChild(cursorEl)
      }

      container.appendChild(cursorsContainer)
    },
  },

  mounted() {
    this.setImageSize()

    let resize = false

    new ResizeObserver(() => {
      if (resize) {
        return
      }

      resize = true
      this.setImageSize()

      setTimeout(() => {
        resize = false
      }, 200)
    }).observe(document.querySelector('.app-content.content'))
  },

  watch: {
    loadingMeasurement(value) {
      if (value) {
        return
      }

      setTimeout(() => this.setImageSize())
    },
    currentTab() {
      this.setImageSize()
    },
    currentMeasurement: {
      deep: true,
      handler(val) {
        const parent =
          router.currentRoute.query.p ||
          store.state.auth.user.adminDocRef ||
          store.state.auth.user.sellerDocRef
        this.fetchMeasurement(parent)
      },
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    currentMeasurement() {
      return this.$store.state.measurements.currentMeasurement || {}
    },
  },

  setup() {
    const MEASUREMENT_APP_STORE_MODULE_NAME = 'measurements'

    if (!store.hasModule(MEASUREMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MEASUREMENT_APP_STORE_MODULE_NAME,
        measurementStoreModule,
      )
    }

    onUnmounted(
      () =>
        store.hasModule(MEASUREMENT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(MEASUREMENT_APP_STORE_MODULE_NAME),
    )

    const {
      fetchMeasurement,
      frontalPicture,
      loadingMeasurement,
      measurement,
      sidePicture,
      cardPicture,
      medicalPrescription,
    } = useMeasurementDetails()

    const parent =
      router.currentRoute.query.p ||
      store.state.auth.user.adminDocRef ||
      store.state.auth.user.sellerDocRef
    fetchMeasurement(parent)

    return {
      frontalPicture,
      loadingMeasurement,
      measurement,
      sidePicture,
      cardPicture,
      medicalPrescription,

      fetchMeasurement,
    }
  },
}
</script>

<style lang="scss">
a[role='tab'].active svg.custom-icon {
  color: white;
}

.header-navbar.floating-nav {
  z-index: 50;
}

.navbar-floating .header-navbar-shadow {
  z-index: 49;
}

.lx__image {
  position: relative;

  height: 100%;
  width: 100%;

  max-height: 400px;

  border-radius: 10px;
  border: 2px solid #04679e;

  overflow: hidden;

  & .cursors-container {
    position: absolute;
    top: 0;
    left: 50%;

    height: 100%;

    pointer-events: none;

    transform: translateX(-50%);

    transition: all 0.2s;

    & .cursor {
      position: absolute;
      pointer-events: none;

      transition: all 0.2s;
    }
  }

  &.hide-markers .cursors-container {
    opacity: 0;
  }

  & img {
    height: 100%;
    width: 100%;

    max-height: 400px;

    object-fit: contain;
    object-position: center;
  }

  & .lx__button-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.lx__subtitle {
  font-size: 0.9rem;
  color: #04679e;
}

.lx__divider {
  width: 100%;
  height: 1px;

  margin-bottom: 0.8rem;

  background-color: #6e6b7b55;
}

.dark-layout .lx__divider {
  background-color: #b4b7bd55;
}
</style>
