<template>
  <svg
    class="custom-icon"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path :d="icon" fill="currentColor"></path>
  </svg>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    icon: String,
  },
}
</script>

<style lang="scss" scoped>
.dark-layout svg.custom-icon {
  color: #b4b7bd;
}

svg.custom-icon {
  color: #6e6b7b;

  size: 14px;
}
</style>
